<template>
  <intersect
    v-if="isShowBrandsIntersect"
    @enter="brandsIntersect = 'Intersected'"
  >
    <section v-if="brands" class="our-brands">
      <div class="container">
        <div class="header-block">
          <h2 class="title">{{ $t("ourBrands") }}</h2>
          <locale-router-link class="header-block__link" to="private-label">
            {{ $t("showMore") }}</locale-router-link
          >
        </div>

        <div class="our-brands__items">
          <swiper
            ref="swiperBannersRef"
            class="mySwiper"
            :options="swiperOption"
          >
            <swiper-slide v-for="(brand, index) in brands" :key="index">
              <router-link
                :to="{ name: 'privateLabel', params: { id: brand.id } }"
                class="our-brands__item"
                @click.native="gtmSelectPromotion(brand)"
                :style="{ backgroundColor: brand.attributes.bg_color_1 }"
              >
                <div class="our-brands__item-container">
                  <div
                    class="our-brands__item-title"
                    data-aos-duration="2000"
                    data-aos="fade-down"
                  >
                    {{ brand.attributes.header }}
                  </div>
                  <div
                    class="our-brands__item-descr"
                    data-aos-duration="1600"
                    data-aos="fade-right"
                  >
                    {{ brand.attributes.slogan }}
                  </div>
                  <div
                    class="our-brands__item-bg"
                    :style="{ backgroundColor: brand.attributes.bg_color_2 }"
                  ></div>
                </div>
                <div
                  class="our-brands__item-img brand-home"
                  :style="{ backgroundImage: getImage(brand) }"
                  data-aos-duration="1500"
                  data-aos="fade-up-left"
                ></div>
              </router-link>
            </swiper-slide>
          </swiper>
          <div class="slider-pagination-wrapper">
            <div class="swiper-pagination" slot="pagination"></div>
          </div>
          <button
            @click="prevSlide"
            class="swiper-button-prev our-brand-arrow our-brand-prev"
          >
            <img src="@/assets/img/icons/arrow-home.svg" alt="" />
          </button>
          <button
            @click="nextSlide"
            class="swiper-button-next our-brand-arrow our-brand-next"
          >
            <img src="@/assets/img/icons/arrow-home.svg" alt="" />
          </button>
        </div>
      </div>
    </section>
  </intersect>
</template>

<script>
import { getAllStoriesByType } from "@/api/story";
import Intersect from "vue-intersect";
import { eventBus } from "@/helpers";
export default {
  name: "BrandsList",
  components: {
    Intersect,
  },
  data() {
    return {
      brands: null,
      brandsIntersect: "",
      isShowBrandsIntersect: false,
      swiperOption: {
        slideTo: 1,
        spaceBetween: 20,
        loop: true,
        observer: true,
        observeParents: true,
        parallax: true,
        loopedSlides: null,
        loopAdditionalSlides: 0,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        breakpoints: {
          900: {
            slidesPerView: 3,
          },
          600: {
            slidesPerView: 2,
          },
          300: {
            slidesPerView: 1,
          },
        },
      },
    };
  },
  created() {
    getAllStoriesByType("stm").then((stories) => {
      this.brands = stories;
      if (this.brands.length === 1) {
        this.swiperOption.breakpoints["1200"].slidesPerView = 1;
        this.swiperOption.loop = false;
      }
      if (this.brands.length === 2) {
        this.swiperOption.breakpoints["1200"].slidesPerView = 2;
        this.swiperOption.loop = false;
      }
      this.brands.sort((a, b) =>
        a.attributes.order > b.attributes.order ? 1 : -1
      );
    });
  },
  watch: {
    brandsIntersect: {
      handler() {
        if (this.brandsIntersect === "Intersected") {
          this.gtmViewPromotion(this.brands);
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.isShowBrandsIntersect = true;
    }, 1700);
  },
  computed: {
    swiper() {
      return this.$refs?.swiperBannersRef;
    },
  },
  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev();
    },
    nextSlide() {
      this.swiper?.swiperInstance.slideNext();
    },
    getImage(item) {
      const image = item?.attributes?.image_preview?.data?.attributes?.url;
      if (image) {
        return "url(" + this.$helpers.getAbsolutePath(image) + ")";
      }
    },
    gtmSelectPromotion(item) {
      eventBus.$emit("gtm", {
        event: "select_promotion",
        type: "select_promotion",
        items: [item],
        urlName: "private-label",
        slot: "Блок Наши бренды",
      });
    },
    gtmViewPromotion(banner) {
      eventBus.$emit("gtm", {
        event: "view_promotion",
        type: "view_promotion",
        urlName: "private-label",
        slot: "Блок Наши бренды",
        items: [...banner],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-button-next {
  left: auto;
  right: -70px !important;
}
.swiper-button-prev {
  left: -70px !important;
}

.swiper-container {
  margin-left: 0;
}
.our-brands__item-img {
  width: 200px;
  height: 225px;
}
.slider-pagination-wrapper {
  display: none;
  bottom: 0px;
}

@media (max-width: 1300px) {
  .swiper-button-next {
    right: -43px !important;
  }
  .swiper-button-prev {
    left: -43px !important;
  }
}

@media (max-width: 600px) {
  .slider-pagination-wrapper {
    display: flex;
  }
  .our-brands__item-descr {
    opacity: 0 !important;
    transform: translateX(-100px) !important;
    transition: all 0.5s linear;
  }
  .our-brands__items {
    overflow: inherit;
  }

  .swiper-container {
    padding: 0 15px;
  }
  .our-brands__item-img {
    width: 150px;
    height: 180px;
    opacity: 0 !important;
    transition: all 0.5s linear;
    transform: translate(100px, 100px) scale(0) !important;
  }
  .our-brands__item-title {
    opacity: 0 !important;
    transition: all 0.8s linear !important;
    transform: translateY(-100px) !important;
  }
  .swiper-slide.swiper-slide-active {
    .our-brands__item-descr {
      opacity: 1 !important;
      transform: translateX(0) !important;
    }
    .our-brands__item-img {
      opacity: 1 !important;
      transform: translate(0, 0) scale(1) !important;
    }
    .our-brands__item-title {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
}
</style>
